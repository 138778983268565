body {
  --monospace-font: "JetBrains Mono", monospace;
  --monospace-size: 10pt;
  font-family: "Rethink Sans", sans-serif;
  --large-shadow: 0px 1px 3px 1px #00000040, 0px 4px 8px 2px #00000030;
  --small-shadow: 0px 1px 3px 1px #00000040;

  --big-border-radius: 10px;
  --small-border-radius: 4px;
  background-color: #c5c5c5;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  padding: 10px;
}

.app.desktop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  align-items: stretch;
  justify-content: stretch;
}

.app.mobile {
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;
}

.color-range-slider {
  display: grid;
  grid-template-areas:
    "l slider r"
    "number number number"
    "name name name";
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: stretch;
  justify-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 10px;
}

.color-range-slider > label:first-child {
  justify-self: end;
  grid-area: l;
}

.color-range-slider > input:first-of-type {
  justify-self: stretch;
  grid-area: slider;
}

.color-range-slider > label:last-of-type {
  justify-self: start;
  grid-area: r;
}

.color-range-slider > span {
  grid-area: number;
}

.color-range-slider > input[type="text"] {
  grid-area: name;
}

button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background-color: #0986c5;
  border: none;
  color: white;
  border-radius: var(--small-border-radius);
  font-size: 11pt;
  font-weight: 600;
  padding: 0.5ex 0.75ex;
  box-shadow: var(--small-shadow);
  transition-duration: 75ms;
  margin: 0.5ex 1ex;
  gap: 1ex;
}

.toggle-set {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  gap: 0;
}

.toggle-set > button {
  margin-inline: 0;
}

.toggle-set > button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-set > button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.desktop .color-range-inputs {
  display: grid;
  grid-template-areas: "colors colors colors copy" "colors colors colors swap";
}

.mobile .color-range-inputs {
  display: grid;
  grid-template-areas: "colors colors" "copy swap";
}

.color-range-inputs > *:first-child {
  grid-area: colors;
}

button:hover {
  background-color: #4ca7d4;
  transition-duration: 75ms;
}

button:active {
  transition-duration: 75ms;
  box-shadow: 0 0 0 0 transparent;
  background-color: #78bddf;
}

button:disabled {
  transition-duration: 75ms;
  box-shadow: 0 0 0 0 transparent;
  background-color: #cacaca;
}

input[type="text"] {
  font-size: 11pt;
  padding: 0.5ex 1ex;
  border: none;
  border-radius: 5px;
  margin: 0.2ex;
  flex-grow: 1;
  flex-shrink: 1;
}

button.toggle.off {
  background-color: #c5c5c5;
  color: #6d6d6d;
  box-shadow: 0 0 0 0 transparent;
  transition-duration: 75ms;
}

button.toggle.on {
  background-color: #0986c5;
  color: white;
  box-shadow: var(--small-shadow);
  transition-duration: 75ms;
}

.main-component {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  box-shadow: var(--large-shadow);
  margin: 10px;
  border-radius: var(--big-border-radius);
}

.mobile .main-component {
  margin: 10px 0;
  padding: 10px;
  flex-grow: 1;
}

.desktop .main-component {
  position: relative;
  top: 0;
  bottom: 0;
  padding: 10px 20px 30px;
}

.color-input-array {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 10px;
  gap: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

.mobile .color-input-array {
  max-height: 50vh;
  overflow-y: auto;
}

.desktop .color-input-array {
  max-height: 70vh;
  overflow-y: auto;
}

.color-input-element {
  box-shadow: var(--small-shadow);
  background-color: #f0f0f0;
  padding: 0.5ex 1ex;
  text-align: center;
  display: grid;
  align-items: center;
  align-content: space-around;
  justify-items: center;
  justify-content: space-around;
}

.color-input-element.custom {
  text-align: center;
  display: grid;
  grid-template-areas: "label label button" "pickerhex pickerhex button";
  grid-template-rows: auto auto auto;
  align-items: center;
  align-content: center;
  justify-items: start;
  justify-content: stretch;
}

.desktop .color-input-element.custom {
  text-align: center;
  display: grid;
  grid-template-areas: "label label pickerhex pickerhex button";
  grid-template-rows: auto auto auto;
  align-items: center;
  align-content: center;
  justify-items: start;
  justify-content: stretch;
}

.color-input-element.static {
  display: grid;
  flex-grow: 1;
  flex-shrink: 1;
  grid-template-areas: "label label" "pickerhex pickerhex";
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  justify-items: start;
  justify-content: stretch;
}

.color-input-element input[type="text"]:last-of-type {
  font-family: var(--monospace-font);
  font-size: var(--monospace-size);
  min-width: 8ex;
}

.color-input-element div {
  grid-area: pickerhex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  justify-items: center;
}

.color-input-element div {
  grid-area: pickerhex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  justify-items: center;
  align-self: center;
  justify-self: stretch;
}

.color-input-element button {
  min-width: 5ex;
  grid-area: button;
  align-self: center;
  justify-self: end;
  flex-grow: 1;
}

.color-input-element label {
  grid-area: label;
}

.color-input-element.custom input[type="text"]:first-of-type {
  justify-self: center;
  width: 90%;
  max-width: 50vw;
  grid-area: label;
  flex-grow: 1;
}

.mobile .color-input-element.custom input[type="text"]:first-of-type {
  justify-self: center;
  max-width: 50vw;
  grid-area: label;
}

.color-input-element input[type="color"] {
  /* grid-area: picker; */
}

.desktop .color-input-element input[type="color"] {
  /* grid-area: picker; */
}

.color-input-element input[type="text"]:last-of-type {
  width: 8ex;
  /* grid-area: hexcode; */
}

.range-type {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.range-type-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.color-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  justify-items: stretch;
  box-shadow: var(--large-shadow);
  margin: 10px 0;
  padding: 10px;
  border-radius: var(--small-border-radius);
}

.mobile .color-list {
  height: 50vh;
  overflow-y: auto;
}

.desktop .color-list {
  max-height: 50vh;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}

.color-list-item {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 2ex;
  font-family: var(--monospace-font);
  font-size: var(--monospace-size);
  padding: 0.5ex;
}

.color-list-item:first-child {
  border-top-left-radius: var(--small-border-radius);
  border-top-right-radius: var(--small-border-radius);
}

.color-list-item:last-child {
  border-bottom-left-radius: var(--small-border-radius);
  border-bottom-right-radius: var(--small-border-radius);
}

.color-list-item p {
  text-align: left;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.footer {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  align-self: flex-end;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: space-evenly;
  text-align: center;
  box-shadow: var(--large-shadow);
  margin: 10px;
  padding: 10px;
  border-radius: var(--big-border-radius);
  height: fit-content;
}
.footer > * {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.footer > a {
  /* background-color: red; */
  text-decoration: none;
}
